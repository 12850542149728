import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { Field, reduxForm, formValueSelector } from 'redux-form/immutable';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import PreviousButtonContent from 'components/PreviousButtonContent';
import { updateUser } from 'containers/UserInformation/actions';
import { setMomentTimezone, timezones, intlTimezones } from 'utils/timezone';

import { COUNTRY } from 'utils/environment';
import { normalizeCanadianPostal, normalizeUSZip } from 'utils/validation';
import {
  validateRequired,
  validateZipCode,
  validatePostalCode,
  validatePhoneNumber,
  normalizePhoneNumber,
} from 'utils/reduxFormHelpers';

import SelectInput from './SelectInput';
import TextInput from './TextInput';

import messages from '../messages';
import { gallivanCountries, genders } from '../helpers';
import { validateBirthday, normalizeDate } from '../../Preferences/helpers';
import { disableGSFields } from 'utils/gsHelper';

import {
  RowWrapper,
  StyledButton,
  ButtonContainer,
  FieldContainer,
} from './StyledClasses';

const DetailForm = (props) => {
  const {
    onUpdated,
    intl,
    goToPrevious,
    isGallivan,
    gallivanCountry,
    isInternational,
    states,
    hidePreviousButton,
    updatePersonalDetails,
    handleSubmit,
    dirty,
    user,
  } = props;
  const provinceOptions = () => {
    let options = [];
    if (COUNTRY === 'CA') {
      options = states.get('canada');
    } else if (COUNTRY === 'US') {
      options = states.get('america');
    }
    return options;
  };
  function onSubmit(values) {
    if (!dirty) {
      return onUpdated();
    }
    if (values.get('timezone')) {
      setMomentTimezone(values.get('timezone'));
    }

    updatePersonalDetails(values);
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FieldContainer>
        <RowWrapper>
          <Field
            required
            component={TextInput}
            label={messages.dateOfBirth}
            name="dob"
            placeholder={intl.formatMessage(messages.datePlaceholder)}
            validate={validateBirthday}
            normalize={normalizeDate}
            disabled={disableGSFields(user.get('profile_type'))}
          />
          <Field
            component={TextInput}
            label={messages.pronouns}
            name="pronoun"
          />
        </RowWrapper>
        <RowWrapper>
          <Field
            component={SelectInput}
            label={messages.gender}
            name="gender"
            options={genders}
          />
        </RowWrapper>
        <RowWrapper>
          <Field
            required
            component={TextInput}
            label={messages.address}
            name="address.street"
            placeholder={intl.formatMessage(messages.streetAddress)}
            validate={validateRequired}
          />
          <Field
            component={TextInput}
            label={messages.alternateStreet}
            name="address.alternate_street"
            placeholder={intl.formatMessage(messages.aptNumber)}
          />
        </RowWrapper>
        {isGallivan ? (
          <RowWrapper>
            <Field
              required
              component={SelectInput}
              label={messages.country}
              name="address.country"
              options={gallivanCountries}
              validate={validateRequired}
            />
            {isInternational ? (
              <Field
                required
                component={TextInput}
                label={messages.zone}
                name="address.zone"
                options={provinceOptions()}
                validate={validateRequired}
              />
            ) : (
              <Field
                required
                component={SelectInput}
                label={messages.stateProvince}
                name="address.state_id"
                options={provinceOptions()}
                validate={validateRequired}
              />
            )}
          </RowWrapper>
        ) : (
          <RowWrapper>
            <Field
              required
              component={TextInput}
              label={messages.country}
              name="address.country"
              disabled
              validate={validateRequired}
            />
            <Field
              required
              component={SelectInput}
              label={messages.stateProvince}
              name="address.state_id"
              options={provinceOptions()}
              validate={validateRequired}
            />
          </RowWrapper>
        )}
        <RowWrapper>
          <Field
            required
            component={TextInput}
            label={messages.cityTown}
            name="address.city"
            validate={validateRequired}
          />
          {isInternational ? (
            <Field
              required
              component={TextInput}
              label={messages.postalCode}
              name="address.code"
            />
          ) : (
            <Field
              required
              component={TextInput}
              label={messages.postalCode}
              name="address.code"
              validate={[
                validateRequired,
                COUNTRY === 'US' ? validateZipCode : validatePostalCode,
              ]}
              normalize={
                COUNTRY === 'US' ? normalizeUSZip : normalizeCanadianPostal
              }
            />
          )}
        </RowWrapper>
        {!isGallivan && (
          <RowWrapper>
            <Field
              required
              component={TextInput}
              label={messages.phoneNumber}
              name="primary_phone_number.number"
              validate={[validateRequired, validatePhoneNumber]}
              normalize={normalizePhoneNumber}
            />
          </RowWrapper>
        )}
        <RowWrapper>
          <Field
            required
            component={SelectInput}
            label={messages.timezones}
            name="timezone"
            options={
              gallivanCountry !== 'Canada' &&
              gallivanCountry !== 'United States'
                ? intlTimezones
                : timezones
            }
            validate={validateRequired}
          />
        </RowWrapper>
        <hr />
        <RowWrapper>
          <Field
            required
            style={{ marginTop: '40px' }}
            component={TextInput}
            label={messages.emergencyName}
            name="emergency_contact.name"
            validate={[validateRequired]}
          />
          <Field
            required
            style={{ marginTop: '40px' }}
            component={TextInput}
            label={messages.emergencyRelationship}
            name="emergency_contact.relationship"
            validate={[validateRequired]}
          />
        </RowWrapper>
        <RowWrapper>
          {isInternational ? (
            <Field
              required
              component={TextInput}
              label={messages.emergencyNumber}
              name="emergency_contact.phone"
              validate={[validateRequired]}
            />
          ) : (
            <Field
              required
              component={TextInput}
              label={messages.emergencyNumber}
              name="emergency_contact.phone"
              validate={[validateRequired, validatePhoneNumber]}
              normalize={normalizePhoneNumber}
            />
          )}
        </RowWrapper>
        <ButtonContainer>
          <StyledButton label={messages.next} secondary type="submit" />
          {!hidePreviousButton && goToPrevious && (
            <PreviousButtonContent
              label={messages.previous}
              onClick={goToPrevious}
            />
          )}
        </ButtonContainer>
      </FieldContainer>
    </form>
  );
};

DetailForm.propTypes = {
  onUpdated: PropTypes.func,
  intl: intlShape,
  goToPrevious: PropTypes.func,
  genderValue: PropTypes.string,
  isGallivan: PropTypes.bool,
  hidePreviousButton: PropTypes.bool,
  isInternational: PropTypes.bool,
  dirty: PropTypes.bool,
  gallivanCountry: PropTypes.string,
  states: PropTypes.object,
  updatePersonalDetails: PropTypes.func,
  handleSubmit: PropTypes.func,
};

function mapStateToProps(state) {
  const user = state.get('user');
  const states = state.get('misc');
  const gallivanCountry = state.getIn([
    'form',
    'yourDetailForm',
    'values',
    'address',
    'country',
  ]);
  const isGallivan = user.get('gallivan');
  const isInternational =
    isGallivan &&
    gallivanCountry !== 'Canada' &&
    gallivanCountry !== 'United States';
  return {
    isGallivan,
    isInternational,
    user,
    states,
    gallivanCountry,
    initialValues: {
      timezone: user.get('timezone'),
      dob: user.get('dob'),
      pronoun: user.get('pronoun'),
      // If gender is not other, get gender. Otherwise set gender dropdown to other
      gender: user.get('gender'),
      address: {
        street: user.getIn(['address', 'street']),
        alternate_street: user.getIn(['address', 'alternate_street']),
        city: user.getIn(['address', 'city']),
        code: user.getIn(['address', 'code']),
        state_id: user.getIn(['address', 'state_id']),
        zone: user.getIn(['address', 'zone']),
        // eslint-disable-next-line no-nested-ternary
        country: user.get('gallivan')
          ? user.getIn(['address', 'country'])
          : COUNTRY === 'US'
          ? 'United States'
          : 'Canada',
      },
      primary_phone_number: {
        number: user.getIn(['primary_phone_number', 'number']),
      },
      emergency_contact: user.get('emergency_contact'),
    },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePersonalDetails: (values, callback) =>
      dispatch(updateUser(values, callback)),
  };
}

const selector = formValueSelector('yourDetailForm');

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    reduxForm({
      form: 'yourDetailForm',
      enableReinitialize: true,
    })(
      connect((state) => {
        const genderValue = selector(state, 'gender');
        return {
          genderValue,
        };
      })(DetailForm),
    ),
  ),
);
