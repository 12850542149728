/**
 *
 * MyCareProvider
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import styled from 'styled-components';
import Breadcrumb from 'components/Breadcrumbs';
import PanelWrapper from 'components/PanelWrapper';
import PanelFullWidth from 'components/PanelFullWidth';
import H1 from 'components/H1';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import { makeSelectUser } from 'containers/Preferences/selectors';
import InvitationCodeDialog from 'containers/Dashboard/InvitationCodeDialog';
import DataLoadingSpinner from 'components/DataLoadingSpinner';
import { phoneSize } from '../../global-styles';
import reducer from './reducer';
import saga from './saga';
import messages from './messages';
import {
  fetchCareTeam,
  fetchCompany,
  fetchCouplesInfo,
  enterPartnerCode,
} from './actions';
import {
  makeSelectCareTeam,
  makeSelectCouplesInfo,
  makeSelectCompany,
} from './selectors';
import ProviderCard from './ProviderCard';

const StyledContainer = styled.div`
  margin: 60px 60px 100px 60px;

  @media (max-width: ${phoneSize}) {
    margin: 60px 20px 100px 20px;
  }
`;
export class MyCareProvider extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      invitationCode: false,
      invitationCodeError: false,
    };
  }
  componentDidMount() {
    this.props.fetchCouplesInfo();
    this.props.fetchCareTeam();
    this.props.fetchCompany();
  }

  openInvitationCodeDialog = () => this.setState({ invitationCode: true });

  renderCards = () => {
    const careTeam = this.props.careTeam.toJS();
    const couplesInfo = this.props.couplesInfo.toJS();
    const { locale } = this.props.intl;

    if (careTeam.loading || couplesInfo.loading) {
      return (
        <StyledContainer>
          <DataLoadingSpinner />
        </StyledContainer>
      );
    }

    const couplesAvailable = couplesInfo.data.available_yn;
    const isGuest = couplesInfo.data.partner_role === 'guest';
    const individualProvider = careTeam.data.individual;
    const couplesProvider = careTeam.data.couples;
    const genericAvailable = careTeam.data.generic_yn;
    const genericProviders = careTeam.data.generic;
    const clinicalNavigatorId = this.props.company.getIn([
      'company_service',
      'clinical_navigator_id',
    ]);
    const isClinicalNavigator =
      genericProviders &&
      clinicalNavigatorId &&
      !!genericProviders.find(
        (e) => Number(e.id) === Number(clinicalNavigatorId),
      );
    const isPracticeSource = this.props.user.get('source') === 'practice';
    return (
      <StyledContainer>
        <ProviderCard
          title={<FormattedMessage {...messages.individualProvider} />}
          provider={individualProvider}
          matchUrl="/match"
          bookingUrl="/sessions/booking"
          isPrivate={isPracticeSource}
        />
        {couplesAvailable && (
          <ProviderCard
            title={<FormattedMessage {...messages.couplesProvider} />}
            provider={couplesProvider}
            matchUrl="/match/couples"
            bookingUrl="/sessions/booking/couples"
            isGuest={isGuest}
            isPrivate={isPracticeSource}
            locale={locale}
            isCouples
            openInvitationCodeDialog={this.openInvitationCodeDialog}
          />
        )}
        {genericAvailable &&
          _.map(genericProviders, (genericProvider, i) => (
            <ProviderCard
              title={
                genericProvider.generic_role || (
                  <FormattedMessage {...messages.genericProvider} />
                )
              }
              key={i}
              provider={genericProvider}
              matchUrl="/match"
              bookingUrl={`/sessions/booking/specialized?provider_id=${genericProvider.id}&role_id=${genericProvider.specialized_role_id}`}
              isGuest={false}
              isPrivate={isPracticeSource || isClinicalNavigator}
            />
          ))}
      </StyledContainer>
    );
  };

  submitInvitationCode = (code) => {
    this.props.enterPartnerCode(
      code,
      () => {
        this.setState({ invitationCode: false, invitationCodeError: false });
        this.props.history.push('/partner_registration');
      },
      () => this.setState({ invitationCodeError: true }),
    );
  };

  render() {
    const { intl } = this.props;

    return (
      <div>
        <Helmet>
          <title>{intl.formatMessage(messages.myCareProvider)}</title>
          <meta name="description" content="My Care Provider" />
        </Helmet>
        <Breadcrumb
          names={[
            <FormattedMessage
              key={'breadcrumb1'}
              {...messages.myCareProvider}
            />,
          ]}
        />
        <PanelWrapper>
          <PanelFullWidth>
            <InvitationCodeDialog
              onSubmit={this.submitInvitationCode}
              onClose={() => this.setState({ invitationCode: false })}
              open={this.state.invitationCode}
              serverError={this.state.invitationCodeError}
            />
            <H1>
              <FormattedMessage {...messages.myCareProvider} />
            </H1>
            {this.renderCards()}
          </PanelFullWidth>
        </PanelWrapper>
      </div>
    );
  }
}

MyCareProvider.propTypes = {
  intl: intlShape,
};

const mapStateToProps = createStructuredSelector({
  couplesInfo: makeSelectCouplesInfo(),
  careTeam: makeSelectCareTeam(),
  company: makeSelectCompany(),
  user: makeSelectUser(),
});

function mapDispatchToProps(dispatch) {
  return {
    fetchCareTeam: () => dispatch(fetchCareTeam()),
    fetchCouplesInfo: () => dispatch(fetchCouplesInfo()),
    fetchCompany: () => dispatch(fetchCompany()),
    enterPartnerCode: (match_code, callback, errCallback) =>
      dispatch(enterPartnerCode(match_code, callback, errCallback)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'myCareProvider', reducer });
const withSaga = injectSaga({ key: 'myCareProvider', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(injectIntl(MyCareProvider));
