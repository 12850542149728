/*
 * LanguageButton Messages
 *
 * This contains all the text for the LanguageButton component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.LanguageButton';

export default defineMessages({
  English: { defaultMessage: 'English' },
  French: { defaultMessage: 'French' },
  Arabic: { defaultMessage: 'Arabic' },
  Bengali: { defaultMessage: 'Bengali' },
  Bosnian: { defaultMessage: 'Bosnian' },
  Cantonese: { defaultMessage: 'Cantonese' },
  Croatian: { defaultMessage: 'Croatian' },
  Farsi: { defaultMessage: 'Farsi' },
  Filipino: { defaultMessage: 'Filipino' },
  Greek: { defaultMessage: 'Greek' },
  German: { defaultMessage: 'German' },
  Hebrew: { defaultMessage: 'Hebrew' },
  Hindi: { defaultMessage: 'Hindi' },
  Hungarian: { defaultMessage: 'Hungarian' },
  Italian: { defaultMessage: 'Italian' },
  Japanese: { defaultMessage: 'Japanese' },
  Mandarin: { defaultMessage: 'Mandarin' },
  Polish: { defaultMessage: 'Polish' },
  Portuguese: { defaultMessage: 'Portuguese' },
  Punjabi: { defaultMessage: 'Punjabi' },
  Russian: { defaultMessage: 'Russian' },
  Serbian: { defaultMessage: 'Serbian' },
  Spanish: { defaultMessage: 'Spanish' },
  Urdu: { defaultMessage: 'Urdu' },
  ASL: { defaultMessage: 'ASL' },
  Macedonian: { defaultMessage: 'Macedonian' },
  Somali: { defaultMessage: 'Somali' },
  Marathi: { defaultMessage: 'Marathi' },
});
