/*
 *
 * Matching actions
 *
 */

import { setUserProperty } from 'utils/amplitude';

import {
  CREATE_MATCH,
  CREATE_MATCH_SUCCESS,
  CREATE_MATCH_ERROR,
  UPDATE_MATCH,
  UPDATE_MATCH_SUCCESS,
  UPDATE_MATCH_ERROR,
  FETCH_CURRENT_MATCH,
  FETCH_CURRENT_MATCH_SUCCESS,
  FETCH_CURRENT_MATCH_ERROR,
  FETCH_MATCH_REPORT,
  FETCH_MATCH_REPORT_SUCCESS,
  FETCH_MATCH_REPORT_ERROR,
  FETCH_MATCH_RESULTS,
  FETCH_MATCH_RESULTS_SUCCESS,
  FETCH_MATCH_RESULTS_ERROR,
  FETCH_PAGINATED_MATCH_RESULTS,
  FETCH_PAGINATED_MATCH_RESULTS_SUCCESS,
  FETCH_PAGINATED_MATCH_RESULTS_ERROR,
  CLEAR_MATCH_RESULTS,
  VERIFY_PHONE_NUMBER,
  VERIFY_PHONE_NUMBER_SUCCESS,
  VERIFY_PHONE_NUMBER_ERROR,
  CREATE_PHONE_NUMBER,
  CREATE_PHONE_NUMBER_SUCCESS,
  CREATE_PHONE_NUMBER_ERROR,
  FETCH_DIRECT_MATCH,
  FETCH_DIRECT_MATCH_SUCCESS,
  FETCH_DIRECT_MATCH_ERROR,
  CLEAR_DIRECT_MATCH,
  SELECT_PRACTITIONER,
  CLEAR_CURRENT_MATCH,
  CONFIRM_PRACTITIONER_MATCH,
  CONFIRM_PRACTITIONER_MATCH_ERROR,
  CONFIRM_PRACTITIONER_MATCH_SUCCESS,
  FETCH_PRACTITIONER,
  FETCH_PRACTITIONER_ERROR,
  FETCH_PRACTITIONER_SUCCESS,
  CREATE_MESSAGE,
  CREATE_MESSAGE_ERROR,
  CREATE_MESSAGE_SUCCESS,
  CONTINUE_FROM_SOURCE,
  CONTINUE_FROM_SOURCE_SUCCESS,
  CONTINUE_FROM_SOURCE_ERROR,
  AUDIT_PROGRESS,
  AUDIT_PROGRESS_ERROR,
  AUDIT_PROGRESS_SUCCESS,
  CHECK_COUGHLIN_EMAIL,
  CHECK_COUGHLIN_EMAIL_SUCCESS,
  CHECK_COUGHLIN_EMAIL_ERROR,
  FETCH_ORS_SCORE,
  FETCH_ORS_SCORE_SUCCESS,
  FETCH_ORS_SCORE_ERROR,
  INVITE_PARTNER,
  CREATE_GUARDIANS,
  CREATE_GUARDIANS_SUCCESS,
  CREATE_GUARDIANS_ERROR,
  FETCH_GUARDIANS,
  FETCH_GUARDIANS_SUCCESS,
  FETCH_GUARDIANS_ERROR,
  FETCH_SPECIALITIES_SUCCESS,
  FETCH_SPECIALITIES,
} from './constants';

export function createMatch(matchType, providerType, callback) {
  return {
    type: CREATE_MATCH,
    matchType,
    providerType,
    callback,
  };
}

export function createMatchSuccess(match) {
  return {
    type: CREATE_MATCH_SUCCESS,
    match,
  };
}

export function createMatchError(error) {
  return {
    type: CREATE_MATCH_ERROR,
    error,
  };
}

export function fetchCurrentMatch(providerType) {
  return {
    type: FETCH_CURRENT_MATCH,
    providerType,
  };
}

export function fetchCurrentMatchSuccess(match) {
  return {
    type: FETCH_CURRENT_MATCH_SUCCESS,
    match,
  };
}

export function fetchCurrentMatchError(error) {
  return {
    type: FETCH_CURRENT_MATCH_ERROR,
    error,
  };
}

export function updateMatch(id, questionCode = '', answers, callback) {
  return {
    type: UPDATE_MATCH,
    id,
    questionCode,
    answers,
    callback,
  };
}

export function updateMatchSuccess(match) {
  return {
    type: UPDATE_MATCH_SUCCESS,
    match,
  };
}

export function updateMatchError(error) {
  return {
    type: UPDATE_MATCH_ERROR,
    error,
  };
}

export function fetchMatchReport(values, callback) {
  return {
    type: FETCH_MATCH_REPORT,
    values,
    callback,
  };
}

export function fetchMatchReportSuccess(results) {
  return {
    type: FETCH_MATCH_REPORT_SUCCESS,
    results,
  };
}

export function fetchMatchReportError(error) {
  return {
    type: FETCH_MATCH_REPORT_ERROR,
    error,
  };
}

export function fetchMatchResults(values, callback) {
  return {
    type: FETCH_MATCH_RESULTS,
    values,
    callback,
  };
}

export function fetchMatchResultsSuccess(results) {
  return {
    type: FETCH_MATCH_RESULTS_SUCCESS,
    results,
  };
}

export function fetchMatchResultsError(error) {
  return {
    type: FETCH_MATCH_RESULTS_ERROR,
    error,
  };
}

export function fetchPaginatedMatchResults(values) {
  return {
    type: FETCH_PAGINATED_MATCH_RESULTS,
    values,
  };
}

export function fetchPaginatedMatchResultsSuccess(results) {
  return {
    type: FETCH_PAGINATED_MATCH_RESULTS_SUCCESS,
    results,
  };
}

export function fetchPaginatedMatchResultsError(error) {
  return {
    type: FETCH_PAGINATED_MATCH_RESULTS_ERROR,
    error,
  };
}

export function clearMatchResults() {
  return {
    type: CLEAR_MATCH_RESULTS,
  };
}

export function verifyPhoneNumber(code, callback, errorCallback) {
  return {
    type: VERIFY_PHONE_NUMBER,
    code,
    callback,
    errorCallback,
  };
}

export function verifyPhoneNumberSuccess(message) {
  return {
    type: VERIFY_PHONE_NUMBER_SUCCESS,
    message,
  };
}

export function verifyPhoneNumberError(error) {
  return {
    type: VERIFY_PHONE_NUMBER_ERROR,
    error,
  };
}

export function createPhoneNumber(values, callback, errorCallback) {
  return {
    type: CREATE_PHONE_NUMBER,
    values,
    callback,
    errorCallback,
  };
}

export function createPhoneNumberSuccess(message) {
  return {
    type: CREATE_PHONE_NUMBER_SUCCESS,
    message,
  };
}

export function createPhoneNumberError(error) {
  return {
    type: CREATE_PHONE_NUMBER_ERROR,
    error,
  };
}

export function fetchDirectMatch(code) {
  return {
    type: FETCH_DIRECT_MATCH,
    code,
  };
}

export function fetchDirectMatchSuccess(match) {
  return {
    type: FETCH_DIRECT_MATCH_SUCCESS,
    match,
  };
}

export function fetchDirectMatchError(error) {
  return {
    type: FETCH_DIRECT_MATCH_ERROR,
    error,
  };
}

export function clearDirectMatch() {
  return {
    type: CLEAR_DIRECT_MATCH,
  };
}

export function selectPractitioner(id) {
  return {
    type: SELECT_PRACTITIONER,
    id,
  };
}

export function clearCurrentMatch() {
  return {
    type: CLEAR_CURRENT_MATCH,
  };
}

export function confirmPractitionerMatch(id, practitionerId, callback) {
  return {
    type: CONFIRM_PRACTITIONER_MATCH,
    id,
    practitionerId,
    callback,
  };
}

export function confirmPractitionerMatchSuccess() {
  return {
    type: CONFIRM_PRACTITIONER_MATCH_SUCCESS,
  };
}

export function confirmPractitionerMatchError(error) {
  return {
    type: CONFIRM_PRACTITIONER_MATCH_ERROR,
    error,
  };
}

export function fetchPractitioner(id) {
  return {
    type: FETCH_PRACTITIONER,
    id,
  };
}

export function fetchPractitionerSuccess(practitioner) {
  return {
    type: FETCH_PRACTITIONER_SUCCESS,
    practitioner,
  };
}

export function fetchPractitionerError(error) {
  return {
    type: FETCH_PRACTITIONER_ERROR,
    error,
  };
}

export function createMessage(
  chatId,
  chatMessage,
  sensitiveYn,
  noAvailabilityYn,
  callback,
) {
  return {
    type: CREATE_MESSAGE,
    chatId,
    chatMessage,
    sensitiveYn,
    noAvailabilityYn,
    callback,
  };
}

export function createMessageSuccess(message) {
  return {
    type: CREATE_MESSAGE_SUCCESS,
    message,
  };
}

export function createMessageError(error) {
  return {
    type: CREATE_MESSAGE_ERROR,
    error,
  };
}

export function continueFromSource(id) {
  return {
    type: CONTINUE_FROM_SOURCE,
    id,
  };
}

export function continueFromSourceSuccess() {
  return {
    type: CONTINUE_FROM_SOURCE_SUCCESS,
  };
}

export function continueFromSourceError(error) {
  return {
    type: CONTINUE_FROM_SOURCE_ERROR,
    error,
  };
}

export function auditProgress(
  page,
  choice,
  trackableId,
  trackableType = 'Match',
) {
  return {
    type: AUDIT_PROGRESS,
    page,
    choice,
    trackableId,
    trackableType,
  };
}

export function auditProgressSuccess() {
  return {
    type: AUDIT_PROGRESS_SUCCESS,
  };
}

export function auditProgressError(error) {
  return {
    type: AUDIT_PROGRESS_ERROR,
    error,
  };
}

export function checkCoughlinEmail(values) {
  return {
    type: CHECK_COUGHLIN_EMAIL,
    values,
  };
}

export function checkCoughlinEmailSuccess() {
  return {
    type: CHECK_COUGHLIN_EMAIL_SUCCESS,
  };
}

export function checkCoughlinEmailError(error) {
  return {
    type: CHECK_COUGHLIN_EMAIL_ERROR,
    error,
  };
}

export function fetchOrsScore(id, callback) {
  return {
    type: FETCH_ORS_SCORE,
    id,
    callback,
  };
}

export function fetchOrsScoreSuccess(score) {
  setUserProperty('ors_score', score);

  return {
    type: FETCH_ORS_SCORE_SUCCESS,
  };
}

export function fetchOrsScoreError(error) {
  return {
    type: FETCH_ORS_SCORE_ERROR,
    error,
  };
}

export function invitePartner(email, callback) {
  return {
    type: INVITE_PARTNER,
    email,
    callback,
  };
}

export function createGuardians(values, callback) {
  return {
    type: CREATE_GUARDIANS,
    values,
    callback,
  };
}

export function createGuardiansSuccess(guardians) {
  return {
    type: CREATE_GUARDIANS_SUCCESS,
    guardians,
  };
}

export function createGuardiansError(error) {
  return {
    type: CREATE_GUARDIANS_ERROR,
    error,
  };
}

export function fetchGuardians(userId, callback) {
  return {
    type: FETCH_GUARDIANS,
    userId,
    callback,
  };
}

export function fetchGuardiansSuccess(guardians) {
  return {
    type: FETCH_GUARDIANS_SUCCESS,
    guardians,
  };
}

export function fetchGuardiansError(error) {
  return {
    type: FETCH_GUARDIANS_ERROR,
    error,
  };
}

export function fetchSpecialities() {
  return {
    type: FETCH_SPECIALITIES,
  };
}
export function fetchSpecialitiesSuccess(result) {
  return {
    type: FETCH_SPECIALITIES_SUCCESS,
    result,
  };
}
